<template>
  <b-container fluid>
    <b-row>
      <b-col>
        Logging out
        <b-spinner small label="Spinning"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'LogoutView',
    methods: {
      logout () {
        console.log('logging out')
        let impersonator = localStorage.getItem('impersonator')
        console.log(impersonator)

        if (impersonator !== null) {
          impersonator = JSON.parse(impersonator)

          localStorage.setItem('jwt', impersonator.jwt)
          localStorage.removeItem('impersonator')
          localStorage.removeItem('debugInfoLevel')
        } else {
          localStorage.removeItem('jwt')
        }

        localStorage.removeItem('forceHostname')
        localStorage.removeItem('userContextToken')

        location.href = '/'
      }
    },
    mounted () {
      this.logout()
    }
  }
</script>